@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  color: theme('colors.black');
  background-color: theme('colors.gold.100');
}

@layer utilities {
  .folded-0 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }

  .folded-l-0.5 {
    clip-path: polygon(
      theme('spacing.2') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.2')
    );
  }

  .folded-l-1 {
    clip-path: polygon(
      theme('spacing.4') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.4')
    );
  }

  .folded-l-2 {
    clip-path: polygon(
      theme('spacing.7') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.7')
    );
  }

  .folded-l-3 {
    clip-path: polygon(
      theme('spacing.10') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.10')
    );
  }

  .folded-l-4 {
    clip-path: polygon(
      theme('spacing.12') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.12')
    );
  }

  .folded-l-5 {
    clip-path: polygon(
      theme('spacing.14') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.14')
    );
  }

  .folded-l-max {
    clip-path: polygon(
      theme('spacing.32') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.32')
    );
  }

  .folded-l-hero {
    clip-path: polygon(
      theme('spacing.64') 0,
      100% 0,
      100% 100%,
      0 100%,
      0 theme('spacing.64')
    );
  }

  @media screen and (max-width: theme('screens.2xl')) {
    .folded-l-hero {
      clip-path: polygon(
        theme('spacing.56') 0,
        100% 0,
        100% 100%,
        0 100%,
        0 theme('spacing.56')
      );
    }
  }

  @media screen and (max-width: theme('screens.xl')) {
    .folded-l-hero {
      clip-path: polygon(
        theme('spacing.32') 0,
        100% 0,
        100% 100%,
        0 100%,
        0 theme('spacing.32')
      );
    }
  }

  @media screen and (max-width: theme('screens.sm')) {
    .folded-l-hero {
      clip-path: polygon(
        theme('spacing.24') 0,
        100% 0,
        100% 100%,
        0 100%,
        0 theme('spacing.24')
      );
    }
  }

  .folded-r-2 {
    clip-path: polygon(
      calc(100% - theme('spacing.7')) 0,
      100% 28px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-3 {
    clip-path: polygon(
      calc(100% - theme('spacing.10')) 0,
      100% 38px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-4 {
    clip-path: polygon(
      calc(100% - theme('spacing.12')) 0,
      100% 48px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-5 {
    clip-path: polygon(
      calc(100% - theme('spacing.14')) 0,
      100% 58px,
      100% 100%,
      0 100%,
      0 0
    );
  }

  .folded-r-max {
    clip-path: polygon(
      calc(100% - theme('spacing.32')) 0,
      100% theme('spacing.32'),
      100% 100%,
      0 100%,
      0 0
    );
  }
}
