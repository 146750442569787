.faq-container {
  max-width: 85vw;

  padding: 1rem 2rem;
  margin: 0 auto;
}
.faq-container-collection {
  max-width: 75vw;

  padding: 1rem 2rem;
  margin: 0 auto;
}
.faq-post-container .post-content iframe {
  width: 100%;
}
.faq-post-container img {
  position: relative;
}
.faq-post-container img:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: all 150ms;

  -webkit-transition: all 100ms;
}
.faq-post-container .post-content img:hover::after {
  opacity: 1;
}
.faq-post-container .post-content pre {
  display: flex;
  flex-wrap: wrap;
}
.faq-post-container .post-content pre > code {
  display: inline-block;
  overflow-x: auto;
}

.faq-post-container {
  max-width: 60vw;
  background: #ffff;

  padding: 1rem;
  margin: 0 auto;
}
.top-img {
  border: 2px solid white;
}
.top-img:nth-child(2) {
  position: absolute;
  left: 42px;
}

.faq-container .author-name {
  color: rgba(46, 122, 122, 0.76);
}

.category.author-name2:nth-child(3)::before {
  content: 'and ';
  margin: 0 5px;
  color: black;
}
.faq-search {
  background: #2174a5;
}
.faq-search input {
  max-width: 80vw;
  width: 100%;
  height: 3rem;
  border-radius: 2rem 0 0 2rem;
  font-size: 20px;
  padding: 0 1rem;
  outline: none;
}
.faq-search button {
  width: 5rem;
  height: 3rem;
  background: #55a2d0;
  padding: 0 1rem;
  color: #ede2e2;
  border-radius: 0 2rem 2rem 0;

  outline: none;
}
.faq-search button:focus {
  outline: none;
}
.faq-search input:focus {
  outline: none;
}
@media (max-width: 980px) {
  .faq-post-container {
    max-width: 70vw;
    padding: 15px;
  }
  .faq-container-collection {
    max-width: 80vw;
    padding: 1rem;
  }
}
@media (max-width: 768px) {
  .faq-post-container {
    max-width: 92vw;
    padding: 10px;
  }
  .faq-container {
    max-width: 90vw;
  }
  .faq-post-container .post-content h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 620px) {
  .faq-container {
    max-width: 90vw;
  }
  .faq-item {
    flex-direction: row;

    padding: 1rem 0;
  }
  .icon-hide {
    display: none;
  }
  .faq-item h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .top-img {
    width: 28px;
    height: 28px;
  }
  .top-img:nth-child(2) {
    left: 25px;
  }
  .intercom-align-left a {
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    line-break: anywhere;
  }
  .faq-search input {
    padding: 0 20px;
  }
  .faq-search button {
    width: 5rem;
  }
}
@media (max-width: 572px) {
  .faq-container,
  .faq-container-collection {
    max-width: 90vw;
  }

  .faq-search input {
    border-radius: 50px;

    padding: 5px 10px;
  }
  .faq-search button {
    border-radius: 50px;
    padding: 5px 0;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .faq-container,
  .faq-container-collection {
    max-width: 90vw;
  }

  .faq-item {
    flex-direction: column;
    padding: 1rem;
  }
  .top-img:nth-child(2) {
    left: 25px;
  }
  .taxnomy {
    font-size: 12px;
  }
}
